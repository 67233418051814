<template>
  <div class="flex flex-col">
    <slot name="top" />

    <div
      class="flex max-w-full flex-row-reverse divide-neutral-200 pb-8 md:divide-x md:divide-x-reverse md:pb-12 md:pt-12"
    >
      <main
        class="flex max-w-full flex-1 flex-col md:w-[1024px] md:max-w-[calc(100%-244px)] md:pl-8 lg:max-w-[calc(100%-397px)] lg:pl-12"
      >
        <slot />
      </main>

      <aside
        ref="sidebar"
        :style="{
          top: `${headerHeight}px`,
          'max-height': `calc(100vh - ${headerHeight}px)`,
        }"
        class="no-scrollbar sticky hidden min-h-full shrink-0 overflow-y-auto md:flex md:w-[245px] md:pr-8 lg:w-[397px] lg:pr-12"
      >
        <slot name="sidebar" />
      </aside>

      <div class="fixed bottom-0 left-0 right-0 z-40 md:hidden">
        <slot name="bottom-sticky" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const headerHeight = inject("headerHeight");
</script>
