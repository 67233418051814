<template>
  <h3 class="headline-xl-2 lg:display-2xl">{{ t("label") }}</h3>
</template>

<script lang="ts" setup>
const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      label: "Filtry",
    },
    en: {
      label: "Filters",
    },
  },
});
</script>
