<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      :class="{ 'z-50': open, 'z-10': !open }"
      class="relative"
      @close="emit('close')"
    >
      <div class="fixed inset-0" />

      <div class="sticky inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <DialogPanel class="pointer-events-auto w-screen">
                <div class="flex h-[100dvh] flex-col bg-white shadow-xl">
                  <div
                    :style="{ 'max-height': visualHeight }"
                    class="flex h-full flex-col"
                  >
                    <div class="shrink-0">
                      <slot name="top-sticky" />
                    </div>

                    <div class="relative grow overflow-auto px-6 py-4">
                      <slot />
                    </div>

                    <div
                      class="relative shrink-0 after:pointer-events-none after:absolute after:bottom-full after:left-0 after:right-0 after:h-20 after:bg-gradient-to-t after:from-white after:content-['']"
                    >
                      <slot name="bottom-sticky" />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

defineProps<{ open: boolean }>();

const emit = defineEmits<{ close: [] }>();

const visualHeight = ref(null);

const resizeHandler = () => {
  visualHeight.value = `${window.visualViewport?.height}px`;
};

onMounted(() => {
  window.visualViewport?.addEventListener("resize", resizeHandler);
});

onUnmounted(() => {
  window.visualViewport?.removeEventListener("resize", resizeHandler);
});

const handleFocus = (event) => {
  if (
    event.target.matches(
      'input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="search"], input[type="tel"], input[type="url"], textarea, [contenteditable="true"]',
    ) &&
    window.visualViewport?.width < 768
  ) {
    setTimeout(() => {
      window.scrollTo(0, -1);

      setTimeout(() => {
        event.target.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 400);
    }, 200);
  }
};

onMounted(() => {
  window.addEventListener("focusin", handleFocus, true);
});

onUnmounted(() => {
  window.removeEventListener("focusin", handleFocus, true);
});
</script>
